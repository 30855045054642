// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'

import firebase from 'firebase/app'
import 'firebase/auth'

// Non on-demand chunks to be shoved into main app.js
import Home from '@/views/Home'

import { setMeta } from '@/metaUtil.js'

const Login = () => import(/* webpackChunkName: "login", webpackPrefetch: true */ '@/views/Login')
const BadPath = () => import('@/components/BadPath')

const Logout = () => import(/* webpackChunkName: "main-c" */ '@/views/Logout')
const UserInfo = () => import(/* webpackChunkName: "main-c" */ '@/views/UserInfo')
const Subscription = () => import(/* webpackChunkName: "main-c" */ '@/views/Subscription')
const Subscribe = () => import(/* webpackChunkName: "subscribe" */ '@/views/Subscribe')
const Document = () => import(/* webpackChunkName: "main-c" */ '@/views/Document')
const DocumentList = () => import(/* webpackChunkName: "main-c" */ '@/views/DocumentList')
const Invoice = () => import(/* webpackChunkName: "main-c" */ '@/views/InvoicePage')
const BlogPost = () => import(/* webpackChunkName: "help" */ '@/views/BlogPost')
const Blog = () => import(/* webpackChunkName: "help" */ '@/views/Blog')

const FAQ = () => import(/* webpackChunkName: "help" */ '@/components/Faq')
const Help = () => import(/* webpackChunkName: "help" */ '@/components/Help')
const HelpTOC = () => import(/* webpackChunkName: "help" */ '@/components/HelpTOC')
const MdViewer = () => import(/* webpackChunkName: "help" */ '@/components/MdViewer')

Vue.use(Router)

var router = new Router({
  mode: 'history',
  // https://router.vuejs.org/en/advanced/scroll-behavior.html
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path:      '/',
      name:      'Home',
      component: Home,
    },
    {
      path:      '/login',
      name:      'Login',
      component: Login,
      meta:      {
        title: 'DartCannon - Login',
      },
    },
    {
      path:      '/logout',
      name:      'Logout',
      component: Logout,
    },
    {
      path:      '/subscribe',
      name:      'Subscribe',
      component: Subscribe,
      meta:      {
        auth:  true,
        title: 'DartCannon - Subscribe',
      },
    },
    {
      path:      '/user',
      name:      'User Info',
      component: UserInfo,
      meta:      {
        auth:  true,
        title: 'DartCannon - User Information',
      },
    },
    {
      path:      '/subscription',
      name:      'User Subscription',
      meta:      { auth: true },
      component: Subscription,
    },
    {
      path:      '/documents',
      name:      'Document List',
      meta:      { auth: true },
      component: DocumentList,
    },
    {
      path:      '/document/:id',
      name:      'document',
      component: Document,
      meta:      {
        auth:      true,
        analytics: {
          pageviewTemplate (route) {
            return {
              title: 'Document View',
              page:  '/document/:id',
            }
          },
        },
      },
    },
    {
      path:      '/blog',
      name:      'Blog',
      component: Blog,
      meta:      {
        title: 'DartCannon Blog',
        auth:  false,
      },
    },
    {
      path:      '/blog/:id',
      name:      'BlogPost',
      meta:      { auth: false, nativeMeta: true },
      component: BlogPost,
      props:     true,
    },
    {
      path:      '/help',
      component: Help,
      children:  [
        {
          path:      '',
          component: HelpTOC,
          name:      'DartCannon - Help TOC',
          meta:      {
            title: 'DartCannon - Help',
          },
        },
        {
          path:      'faq',
          component: FAQ,
          name:      'DartCannon - Frequently Asked Questions',
          meta:      {
            title: 'DartCannon - Frequently Asked Questions',
          },
        },
        {
          path:      ':page*',
          component: MdViewer,
          name:      'Markdown Page',
          meta:      { auth: false, nativeMeta: true },
          props:     true,
        },
      ],
      meta: {
        auth: false,
      },
    },
    {
      path: '/invoice/:id',
      name: 'Invoice',
      meta: {
        auth:      true,
        blank:     true,
        analytics: {
          pageviewTemplate (route) {
            return {
              title: 'Invoice',
              page:  '/invoice/:id',
            }
          },
        },
      },
      component: Invoice,
    },
    /* Catch all for unmatched routes */
    {
      path:      '*',
      name:      'Unknown',
      component: BadPath,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.state.user.authenticated === true) {
      next()
    } else if (store.state.user.authenticated === false) {
      next({
        path:  '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      // if isLoggedIn is undefined, it means we're waiting on firebase to
      // initialize. Wait on initialization to complete an re-check
      const unsub = firebase.auth().onAuthStateChanged((user) => {
        unsub()
        // We can't check the store here because of firing order of the watcher
        // may not have run. This assumes that if you're a user, you have
        // access
        if (user) {
          next()
        } else {
          next({
            path:  '/login',
            query: { redirect: to.fullPath },
          })
        }
      })
    }
  } else {
    next() // make sure to always call next()!
  }
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    if (!to.meta.nativeMeta) {
      setMeta({
        title: to.meta.title || 'DartCannon',
        url:   to.path,
      })
    }
  })
})

export default router
