<!-- Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied. -->
<template>
  <v-app v-scroll="onScroll">
    <v-toolbar
      :fixed="isHome"
      :app="!isHome"
      dark
      flat
      v-if="!$route.meta.blank"
      :class="toolbarClass">
      <v-toolbar-title
        v-show="homeScrolled || !isHome" >
        <router-link
          class="white--text"
          to='/'>
          <v-avatar
            size="34px"
            class="mr-2"
            tile>
            <img src="/static/logo-white.png">
          </v-avatar>
          DartCannon
        </router-link>
      </v-toolbar-title>
      <v-spacer/>
      <v-toolbar-items>
        <v-menu
          bottom
          left
          dark
          open-on-hover
          offset-y>
          <v-btn
            slot='activator'
            to="/help"
            flat>Help</v-btn>
          <v-list :style="menuListStyle" >
            <v-list-tile to="/help/faq">
              <v-list-tile-title>FAQ</v-list-tile-title>
            </v-list-tile>
            <v-list-tile to="/help/getting-started">
              <v-list-tile-title>Getting Started</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              exact
              to="/blog">
              <v-list-tile-title>Blog</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn
          flat
          to="/login"
          v-if="!loggedIn">Login or Signup</v-btn>
        <v-btn
          flat
          to="/documents"
          v-if="loggedIn">My Simulations</v-btn>
        <v-menu
          bottom
          left
          dark
          open-on-hover
          offset-y
          v-if="loggedIn">
          <v-btn
            slot='activator'
            flat
            dark>
            <v-avatar size="34px">
              <img
                :src="avatar"
                alt="Avatar"
                v-if="avatar">
              <v-icon
                large
                dark
                v-else>account_circle</v-icon>
            </v-avatar>
          </v-btn>
          <v-list :style="menuListStyle" >
            <v-list-tile to="/subscription">
              <v-list-tile-title>Subscription</v-list-tile-title>
            </v-list-tile>
            <v-list-tile to="/user">
              <v-list-tile-title>User Settings</v-list-tile-title>
            </v-list-tile>
            <v-divider/>
            <v-list-tile to="/logout">
              <v-list-tile-title>Logout</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-content>
      <v-container
        fluid
        :class="isHome ? 'pa-0' : ''">
        <router-view/>
      </v-container>
    </v-content>
    <v-footer
      v-if="!$route.meta.blank"
      app
      absolute
      dark
      class="primary white--text">
      <v-btn
        dark
        flat
        to="/help/legal/terms-of-service">Terms of Service</v-btn>
      <v-btn
        dark
        flat
        to="/help/legal/privacy-policy">Privacy Policy</v-btn>
      <v-spacer/>
      <v-btn
        dark
        flat
        icon
        exact
        to="/blog">
        <v-icon>rss_feed</v-icon>
      </v-btn>
      <v-btn
        dark
        flat
        icon
        href="https://twitter.com/dartcannon">
        <v-icon>fa-twitter</v-icon>
      </v-btn>
      <div>© {{ new Date().getFullYear() }} DecElite LLC.</div>
    </v-footer>
    <sub-dialog/>
  </v-app>
</template>
<script>
import * as VToolbar from 'vuetify/es5/components/VToolbar'
import * as VList from 'vuetify/es5/components/VList'
import VMenu from 'vuetify/es5/components/VMenu'
import VBtn from 'vuetify/es5/components/VBtn'
import VAvatar from 'vuetify/es5/components/VAvatar'
import VIcon from 'vuetify/es5/components/VIcon'
import * as VGrid from 'vuetify/es5/components/VGrid'
import VFooter from 'vuetify/es5/components/VFooter'
import VDivider from 'vuetify/es5/components/VDivider'
import { Scroll } from 'vuetify/es5/directives'

import { mapState } from 'vuex'
import SubDialog from './components/SubDialog'

export default {
  data () {
    return {
      offsetTop: 0,
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.user.authenticated,
      avatar:   state => state.user.user.photoUrl,
    }),
    isHome () {
      return this.$route.path === '/'
    },
    homeScrolled () {
      return this.isHome && this.offsetTop > 250
    },
    toolbarClass () {
      let base = 'dc--toolbar--main'
      if (!this.isHome || this.homeScrolled) base += ' primary'
      else base += ' transparent'
      return base
    },
    transparentMenu () {
      return this.isHome && this.offsetTop < 200
    },
    menuListStyle () {
      return this.transparentMenu
        ? 'background: rgba(66,66,66,.3)'
        : ''
    },
  },
  methods: {
    onScroll () {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
    },
  },
  components: {
    SubDialog,
    ...VToolbar,
    ...VList,

    VFooter,
    VMenu,
    VBtn,
    VAvatar,
    VIcon,
    ...VGrid,
    VDivider,
  },
  directives: {
    Scroll,
  },
}
</script>
<style>
  .dc--toolbar--main .v-toolbar__title {
    font-weight: 300;
  }

  .dc--toolbar--main .v-toolbar__title a {
    text-decoration-line: none;
  }
</style>
