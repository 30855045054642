// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import firebase from 'firebase/app'
import 'firebase/database'
import { commits } from './mutations'

export const actions = {
  setDoc: ({ state, dispatch, commit }, params) => {
    if (state.key !== params.key) {
      if (state.key) {
        firebase.database().ref(state.dbPath).off()
        commit(commits.reset)
      }
      commit(commits.set_key, {
        key:  params.key,
        meta: {
          analytics: [
            ['event', 'document', 'load', params.key],
          ],
        },
      })
    }
    if (!state.mounted) {
      commit(commits.loading)
      commit(commits.mounted)
      firebase.database().ref(state.dbPath).on('value', (snapshot) => {
        // TODO: this might benefit from taking a diff then setting only changed
        commit(commits.set_doc, snapshot.val())
      })
    }
    return Promise.resolve(params.key)
  },
  clearDoc ({ state, commit }) {
    if (state.dbPath) firebase.database().ref(state.dbPath).off()
    commit(commits.reset)
    return Promise.resolve()
  },
  // All keys in update should be relative to dbPath
  sendUpdate ({ commit, getters, state, rootState }, update) {
    update['updated'] = getters.timestamp
    const metaFields = ['title', 'created', 'updated', 'type', 'description']
    update = Object.keys(update)
      .reduce((r, f) => {
        if (metaFields.includes(f)) {
          r[`${rootState.user.userPath}/docs/${state.key}/${f}`] = update[f]
        }
        r[state.dbPath + '/' + f] = update[f]
        return r
      }, {})

    // if (process.env !== 'production') console.log(update)

    return firebase.database().ref().update(update)
  },
  setDocValue ({ state, commit, getters, dispatch, rootState }, payload) {
    // Update local immediately
    commit(commits.update, payload)

    return dispatch('sendUpdate', payload)
  },

  // There is a lot of syncing here to denormalize data
  // May need to wait on server to get key...
  addItem ({ dispatch, commit, getters, state, rootState }, item) {
    let key = firebase.database().ref(state.dbPath).child('items').push().key

    // Always put the new item at the end
    item.weight = getters[`${item.type}s`].length

    commit(commits.set_item, {
      key,
      item,
      meta: { analytics: [
        ['event', 'document', `new_${item.type}`, state.document.type, item.weight],
      ] } })

    const update = {}
    update['items/' + key] = item

    return dispatch('sendUpdate', update)
  },
  rmItem ({ state, commit, dispatch, getters }, key) {
    const type = state.document.items[key].type
    const titleType = type.charAt(0).toUpperCase() + type.slice(1)
    const rmIdx = getters[`${type}s`].findIndex(i => i.key === key)
    const toMap = getters[`${type}s`].map((i, idx) => {
      if (idx === rmIdx) return -1
      if (idx > rmIdx) return idx - 1
      return idx
    })

    commit(commits.rm_item, { key,
      meta: { analytics: [
        ['event', 'document', `rm${titleType}`, state.document.type, getters[`${type}s`].length],
      ] },
    })

    return dispatch('reWeight', { type, toMap, sendUpdate: false })
      .then(weightUpdates => {
        const update = {
          ['items/' + key]: null,
          ...weightUpdates,
        }

        return dispatch('sendUpdate', update)
      })
  },
  updateItem ({ state, dispatch }, { key, payload }) {
    const update = Object.keys(payload).reduce((r, f) => {
      r['items/' + key + '/' + f] = payload[f]
      return r
    }, {})
    return dispatch('sendUpdate', update)
  },
  /**
   * reWeight
   * takes type: [ item / risk ]
   * returns list of promises, ready for promise.all
   */
  reWeight ({ state, getters, dispatch, commit }, { type, toMap, sendUpdate = true }) {
    if (toMap === undefined) {
      // If toMap isn't given, just do a simple weight fix
      toMap = getters[`${type}s`].map((i, idx) => idx)
    }

    // if we've deleted an item and it is already gone, so need to be more
    // clever... prior map uses original mapping while actual objects just
    // re-weight.
    const priorMap = toMap
    if (getters[`${type}s`].length < toMap.length) {
      toMap = getters[`${type}s`].map((i, idx) => idx)
    }

    // no weight changes
    if (getters[`${type}s`].every((i, idx) => i.weight === toMap[idx] ||
                                             toMap[idx] === -1)) {
      Promise.resolve()
    }

    // What type of items do we need to check?
    const types = []
    if (type === 'item') {
      types.push({ type: 'items', param: 'prior' })
    }
    // because risks references items in plans, always check risks in plans
    if (type === 'risk' || getters.type === 'plan') {
      types.push({ type: 'risks', param: 'task' })
    }

    const update = {}
    types.forEach(t => {
      getters[t.type].forEach((i, idx) => {
        // don't do anything if this is a deleted item
        if (t.type === `${type}s` && toMap[idx] === -1) return
        // If this item has been moved, reweigh
        if (t.type === `${type}s` && toMap[idx] !== i.weight) {
          commit(commits.update_item, {
            key:     i.key,
            payload: {
              weight: toMap[idx],
            },
          })
          update['items/' + i.key + '/weight'] = toMap[idx]
        }
        if (getters.type === 'plan' &&
          type === 'item' &&
          i.hasOwnProperty(t.param) &&
          i[t.param] !== '') {
          let priors = i[t.param].split(',').map(v => parseInt(v.trim()) - 1)
          if (priors.some(p => priorMap[p] !== p)) {
            priors = priors.map(i => priorMap[i])
              .filter(v => v !== -1 && v !== undefined)
              .sort()
            update['items/' + i.key + '/' + t.param] = priors.map(v => v + 1).join(', ')
          }
        }
      })
    })
    if (sendUpdate) {
      return dispatch('sendUpdate', update)
    }
    return update
  },
  move ({ dispatch, getters }, { type, from, to }) {
    const idxMap = getters[`${type}s`].map((i, idx) => idx)

    var mvKey = idxMap[from]
    idxMap.splice(from, 1)
    idxMap.splice(to, 0, mvKey)

    const toMap = idxMap.reduce((r, v, idx) => {
      r[v] = idx
      return r
    }, Array(idxMap.length))

    return dispatch('reWeight', { type, toMap })
  },
}
