// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
/**
 * User Store Module
 *
 * handles login and sync of the user
 */

import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

const userStore = {
  state: {
    authenticated: null, // null indicates we haven't loaded yet...
    id:            '',
    userPath:      '',
    userRoPath:    '',
    user:          {},
    userRo:        {},
    loading:       {
      user:   true,
      userRo: true,
    },
  },
  mutations,
  actions,
  getters,
}

export default userStore
