// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
export const sortItems = function (items, type) {
  return Object.entries(items)
    .filter(entry => {
      return entry[1].type === type
    })
    .map((entry, idx) => {
      const item = {
        key: entry[0],
        ...entry[1],
      }
      if (entry[1].hasOwnProperty('estimateParameters')) {
        item.min = entry[1].estimateParameters.min
        item.mode = entry[1].estimateParameters.mode
        item.max = entry[1].estimateParameters.max
      }
      return item
    })
    .sort((a, b) => {
      if (a.weight > b.weight) {
        return 1
      } else if (a.weight < b.weight) {
        return -1
      } else {
        return 0
      }
    })
}

export const getters = {
  items: state => {
    if (state.document.hasOwnProperty('items')) {
      return sortItems(state.document.items, 'item')
    } else {
      return []
    }
  },
  risks: state => {
    if (state.document.hasOwnProperty('items')) {
      return sortItems(state.document.items, 'risk')
    } else {
      return []
    }
  },
  itemAllocation: state => {
    if (!state.document.hasAllocation) { return 0 }
    if (!state.document.hasOwnProperty('items')) { return 0 }
    return Object.entries(state.document.items)
      .reduce((sum, entry) => {
        if (entry[1].type === 'item') { return sum + entry[1].allocated }
        return sum
      }, 0)
  },
  itemUsed: state => {
    if (!state.document.hasAllocation) { return 0 }
    if (!state.document.hasOwnProperty('items')) { return 0 }
    return Object.entries(state.document.items)
      .reduce((sum, entry) => {
        if (entry[1].type === 'item') { return sum + entry[1].used }
        return sum
      }, 0)
  },
  riskAllocation: state => {
    if (!state.document.hasAllocation) { return 0 }
    if (!state.document.hasOwnProperty('items')) { return 0 }
    return Object.entries(state.document.items)
      .reduce((sum, entry) => {
        if (entry[1].type === 'risk') { return sum + entry[1].allocated }
        return sum
      }, 0)
  },
  riskUsed: () => {
    return 0
  },
  docReady: state => {
    return state.loaded &&
           state.document.hasOwnProperty('created') &&
           state.document.hasOwnProperty('updated') &&
           state.document.created != null &&
           state.document.updated != null &&
           state.document.created > 0 &&
           state.document.updated > 0
  },
  itemCount: state => {
    if (!state.document.items) return 0
    return Object.keys(state.document.items).length
  },
}

export default getters
