// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
// Vue version set in webpack.base.conf in the build directory
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import Vuetify from 'vuetify/es5/components/Vuetify'
import VApp from 'vuetify/es5/components/VApp'

import App from './App'
import router from './router'
import store from './store'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import 'vuetify/src/stylus/app.styl' // eslint-disable-line

Vue.config.productionTip = false

Vue.use(Vuetify, {
  // There are a ton of tokens with "spelling issues" here that are unique...
  /* eslint-disable spellcheck/spell-checker */
  theme: {
    primary:   '#01579b', // light-blue darken-4
    secondary: '#455a64', // blue-grey darken-2
    accent:    '#40c4ff', // light-blue accent-2
    info:      '#42a5f5', // blue lighten-1
    warning:   '#ffa000', // amber darken-2
    error:     '#d50000', // red accent-4
    success:   '#81c784', // green lighten-2
  },
  /* eslint-enable spellcheck/spell-checker */
  components: {
    VApp,
    Vuetify,
  },
})

const DEBUG_GA = false

Vue.use(VueAnalytics, {
  id: GA_ID, //eslint-disable-line
  router,
  // TODO: This should go away once vue-analytics can handle our document
  // management process
  autoTracking: {
    exception:      true,
    pageviewOnLoad: false,
  },
  debug: {
    enabled:     DEBUG_GA,
    trace:       false,
    sendHitTask: !DEBUG_GA,
  },
})

// defined from webpack
var firebaseConfig = FIREBASE_CONFIG; //eslint-disable-line
var stripePk = STRIPE_PK; //eslint-disable-line

firebase.initializeApp(firebaseConfig).auth()

/* eslint-disable no-new */
let root = new Vue({
  router,
  store,
  render: h => h(App),

  data: {
    // Placeholder - this gets mounted by Login.vue
    firebaseAuthUi: null,
    stripePk:       stripePk,
  },
  beforeCreate: function () {
    store.dispatch('register')
  },
  mounted: function () {
    // Track direct access vs SPA navigation
    this.$ga.event('site', 'load')
  },
})

document.addEventListener('DOMContentLoaded', function () {
  root.$mount('#app')
})
