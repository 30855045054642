// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { commits } from './mutations'

export const actions = {
  logout ({ state, commit }) {
    // We reset in the listener
    commit('noop', { meta: { analytics: [
      ['event', 'user', 'logout'],
    ] } })
    return firebase.auth().signOut()
  },
  register ({ state, commit, dispatch }) {
    return firebase.auth().onAuthStateChanged(user => {
      if (user && !user.isAnonymous) {
        commit(commits.success, {
          user,
          meta: { analytics: [
            ['set', 'userId', user.uid],
            ['event', 'user', 'authenticated', user.providerData[0].providerId],
          ] },
        })
        commit(commits.loading)
        firebase.database().ref(state.userPath).on('value', snapshot => {
          commit(commits.set_user, snapshot.val())
        })
        firebase.database().ref(state.userRoPath).on('value', snapshot => {
          commit(commits.set_user_ro, snapshot.val())
        })
        dispatch('syncUser', user)
      } else {
        if (state.userPath) firebase.database().ref(state.userPath).off()
        if (state.userRoPath) firebase.database().ref(state.userRoPath).off()
        commit(commits.reset, {
          meta: { analytics: [
            ['set', 'userId', null],
          ] },
        })
        dispatch('clearDoc')
        dispatch('clearInvoice')
      }
    })
  },
  resetError ({ state }) {
    return firebase.database().ref(state.userPath).child('/status/error').set(null)
  },
  setError ({ state }, msg) {
    if (!state.userPath) return Promise.resolve()
    return firebase.database().ref(state.userPath).child('/status').set({
      working: false,
      error:   true,
      message: msg,
    })
  },
  clearStatus ({ state }) {
    return firebase.database().ref(state.userPath).child('/status').set(null)
  },
  syncUser ({ commit, state, getters, dispatch }, user) {
    return firebase.database().ref(state.userPath).once('value', (data) => {
      var update = {
        email:         user.email,
        displayName:   user.displayName,
        emailVerified: user.emailVerified,
        photoUrl:      user.photoURL,
        providerId:    user.providerData[0].providerId,
        lastLoggedIn:  getters.timestamp,
      }
      if (data.val() === null) {
        if (!user.emailVerified) {
          dispatch('sendEmailVerification')
        }
        update.joined = getters.timestamp
        update.seen = {
          list:   false,
          budget: false,
          plan:   false,
        }
        update.wanted = {
          plan: 'free',
        }
      }
      return data.ref.update(update)
    })
  },
  userSeen ({ commit, getters, state }, type) {
    commit('noop', { meta: { analytics: [
      ['event', 'user', 'seen', type],
    ] } })
    return firebase.database()
      .ref(state.userPath).child(`seen/${type}`)
      .set(getters.timestamp)
  },
  rmUserDoc ({ state }, key) {
    return firebase.database().ref(state.userPath + '/docs').update({ [key]: null })
  },
  deleteUser ({ commit, state }) {
    commit('noop', { meta: { analytics: [
      ['event', 'user', 'delete'],
    ] } })
    if (state.authenticated && state.id === firebase.auth().currentUser.uid) {
      return firebase.auth().currentUser.delete()
    }
    return Promise.resolve()
  },
  setStripeToken ({ commit, getters, state }, result) {
    commit('noop', { meta: { analytics: [
      ['event', 'subscription', 'change', 'token'],
    ] } })
    return Promise.all([
      firebase.database().ref(state.userPath).child('/wanted/token').set(result),
    ])
  },
  setWantedPlan ({ commit, getters, state }, plan) {
    commit('noop', { meta: { analytics: [
      ['event', 'subscription', 'change', 'plan'],
    ] } })
    return firebase.database().ref(state.userPath).child('wanted/plan').set(plan)
  },
  setWantedPlanToken ({ commit, getters, state }, { plan, token }) {
    commit('noop', { meta: { analytics: [
      ['event', 'subscription', 'change', 'plan+token'],
    ] } })
    return Promise.all([
      firebase.database().ref(state.userPath).child('wanted').update({
        plan,
        token,
      }),
    ])
  },
  sendEmailVerification ({ commit }) {
    let user = firebase.auth().currentUser
    if (user != null) {
      if (!user.emailVerified) {
        commit('noop', { meta: { analytics: [
          ['event', 'user', 'sendVerification'],
        ] } })
        user.sendEmailVerification()
      }
    }
  },
  setDisplayName ({ commit, getters, state }, name) {
    name = `${name}`.trim()
    if (name === '') {
      return Promise.reject(new Error('DisplayName must not be empty'))
    }
    if (name === state.user.displayName) { return Promise.resolve() }
    return firebase.auth().currentUser.updateProfile({
      displayName: name,
    }).then(r => {
      commit('noop', { meta: { analytics: [
        ['event', 'user', 'changeDisplayName'],
      ] } })
      return firebase.database().ref(state.userPath).update({
        displayName: name,
      })
    })
  },
  changePassword ({ commit, state }, { currentPass, newPass }) {
    let cred = firebase.auth.EmailAuthProvider.credential(
      state.user.email,
      currentPass
    )
    return firebase.auth().currentUser.reauthenticateWithCredential(cred)
      .then(() => {
        commit('noop', { meta: { analytics: [
          ['event', 'user', 'changePassword'],
        ] } })
        return firebase.auth().currentUser.updatePassword(newPass)
      })
  },
}
