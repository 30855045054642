// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import firebase from 'firebase/app'
import 'firebase/database'

// Typically called from a document list which is why we can't just call
// document.update as there isn't a document loaded. Also updates user docs.
//
// NOTE: once sharing is added, this cannot update all shared users because
// of write permissions. "shared" users should be updated via server function.
// Alternatively, move to doc store and use searches instead of user document
// lists
export const renameDoc = function ({ commit, state, dispatch, getters }, { key, newTitle }) {
  commit('noop', { meta: { analytics: [
    ['event', 'document', 'rename'],
  ] } })
  return Promise.all([
    firebase.database()
      .ref(`/documents/${key}`)
      .update({
        title:   newTitle,
        updated: getters.timestamp,
      }),
    dispatch('addUserDoc', {
      key,
      payload: {
        title:   newTitle,
        updated: getters.timestamp,
      },
    }),
  ])
}
