// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
// Sets meta for open-graph
// Properties in meta:
//   * title
//   * url
//   * image
//   * description
// Will set any additional if given
// Takes relative url and attaches the base
const BASE = HOST_BASE //eslint-disable-line

// NOTE - written to maximize compatibility (minimize requires)
export function setMeta (meta) {
  meta.title = meta.title || 'DartCannon'
  // NOTE: cannot use document.hostname because of prerender!
  // We append a '/' because router doesn't care, but prerender redirects...
  meta.url = BASE + (meta.url || document.location.pathname) + '/'
  // TODO: need a better default header image
  meta.image = BASE + (meta.image || '/static/card_header.png')
  meta.description = meta.description || 'DartCannon makes it easy to simulate complex plans and budgets using advanced Monte Carlo methods.'
  meta.type = meta.type || 'website'

  document.title = meta.title
  const nodes = document.querySelectorAll('meta[property^="og:"]')
  for (let i = 0; i < nodes.length; i++) {
    const property = nodes[i].getAttribute('property').slice(3)
    if (meta.hasOwnProperty(property)) {
      nodes[i].content = meta[property]
      delete meta[property]
    } else {
      nodes[i].remove()
    }
  }

  for (const key in meta) {
    const e = document.createElement('meta')
    e.setAttribute('property', `og:${key}`)
    e.content = meta[key]
    document.getElementsByTagName('head')[0].appendChild(e)
  }
}
