// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
/**
 * Subscription Dialog
 *
 * handles control & state to subscription module
 */

// Mutations
const OPEN = 'OPEN_SUB_DIALOG'
const CLOSE = 'CLOSE_SUB_DIALOG'

const subDialogStore = {
  state: {
    open:    false,
    type:    null,
    resolve: null,
    reject:  null,
    promise: null,
  },

  mutations: {
    [OPEN] (state, { type }) {
      state.open = true
      state.type = type
    },
    [CLOSE] (state) {
      state.open = false
      state.resolve = null
      state.reject = null
      state.promise = null
    },
    PROMISE (state) {
      if (state.promise === null) {
        state.promise = new Promise(function (resolve, reject) {
          state.resolve = resolve
          state.reject = reject
        })
      }
    },
  },
  actions: {
    openSubDialog ({ commit, state }, type = 'docs') {
      commit(OPEN, {
        type,
        meta: { analytics: [
          ['event', 'subDialog', 'opened', type],
        ] },
      })

      commit('PROMISE')
      return state.promise
    },
    closeSubDialog ({ commit, getters, state }) {
      if (state.promise !== null && getters.paidStatus) state.resolve()
      else if (state.promise !== null && !getters.paidStatus) state.reject()

      commit(CLOSE, {
        meta: { analytics: [
          ['event', 'subDialog', 'closed'],
        ] },
      })

      return Promise.resolve()
    },
    wantTo ({ state, dispatch, getters, rootState }, { need, comp, silent = false }) {
      if (getters.paidStatus) return Promise.resolve()
      if (state.promise !== null) return state.promise

      if (need === 'items' && getters.itemCount < 5) return Promise.resolve()
      if (need === 'docs' && getters.userDocuments.length < 5) return Promise.resolve()
      if (need === 'sim' && comp <= 10000) return Promise.resolve()

      if (silent) return Promise.reject(new Error('Subscription not confirmed'))

      return dispatch('openSubDialog', need)
    },
  },
}

export default subDialogStore
