// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/database'
import { analyticsMiddleware } from 'vue-analytics'

import * as actions from './actions'

import UserMod from './modules/user'
import Document from './modules/document'
import Invoice from './modules/invoice'
import SubDialog from './modules/subDialog'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user:       UserMod,
    simulation: Document,
    invoice:    Invoice,
    subDialog:  SubDialog,
  },
  mutations: {
    // This is for catching analytics calls when we're not actually performing
    // a mutation but still want to send analytics
    noop () { },
  },
  actions,
  getters: {
    timestamp: () => {
      return firebase.database.ServerValue.TIMESTAMP
    },
  },
  plugins: [ analyticsMiddleware ],
  strict:  process.env.NODE_ENV !== 'production', // Shouldn't use strict in prod
})

if (module.hot) {
  module.hot.accept([
    './actions',
    './modules/user',
    './modules/user/mutations',
    './modules/user/actions',
    './modules/user/getters',
    './modules/document',
    './modules/document/mutations',
    './modules/document/actions',
    './modules/document/getters',
    './modules/document',
    './modules/invoice',
    './modules/subDialog',
  ], () => {
    store.hotUpdate({
      actions: require('./actions').default,
      modules: {
        user:       require('./modules/user').default,
        simulation: require('./modules/document').default,
        invoice:    require('./modules/invoice').default,
        subDialog:  require('./modules/subDialog').default,
      },
    })
  })
}

export default store
