// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import Vue from 'vue'

export const commits = {
  set_key:     'DOC_SET_KEY',
  loading:     'DOC_LOADING',
  loaded:      'DOC_LOADED',
  mounted:     'DOC_MOUNTED',
  err:         'DOC_ERR',
  reset:       'DOC_RESET',
  unlisten:    'DOC_UNLISTEN',
  set_doc:     'DOC_SET',
  update:      'DOC_UPDATE',
  set_item:    'DOC_SET_ITEM',
  update_item: 'DOC_UPDATE_ITEM',
  rm_item:     'DOC_RM_ITEM',
}

export const mutations = {
  [commits.set_key] (state, { key }) {
    if (state.key !== key) {
      state.key = key
      state.dbPath = '/documents/' + key
      state.loaded = false
      state.loading = false
      state.synced = false
      state.mounted = false
    }
  },
  [commits.loading] (state) {
    state.loading = true
    state.loaded = false
    state.error = null
  },
  [commits.loaded] (state) {
    state.loading = false
    state.loaded = true
  },
  [commits.err] (state, { err }) {
    state.loading = false
    state.loaded = false
    state.error = err
  },
  [commits.reset] (state) {
    state.key = ''
    state.dbPath = ''
    state.loaded = false
    state.loading = false
    state.mounted = false
    state.error = null
  },
  [commits.mounted] (state) {
    state.mounted = true
  },
  [commits.set_doc] (state, doc) {
    state.document = doc
    state.loaded = true
    state.loading = false
    state.synced = true
  },
  [commits.update] (state, payload) {
    Object.keys(payload).forEach(f => {
      Vue.set(state.document, f, payload[f])
    })
  },
  [commits.set_item] (state, { key, item }) {
    if (!state.document.items) state.document.items = {}
    state.document.items[key] = item
  },
  [commits.rm_item] (state, key) {
    Vue.delete(state.document.items, key)
  },
  [commits.update_item] (state, { key, payload }) {
    Object.keys(payload).forEach(f => {
      Vue.set(state.document.items[key], f, payload[f])
    })
  },
}
