// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import firebase from 'firebase/app'
import 'firebase/database'

const SET_KEY = 'SET_INVOICE'
const LOADED = 'INVOICE_LOADED'
const LOADING = 'INVOICE_LOADING'
const ERR = 'INVOICE_ERR'
const CLEAR = 'INVOICE_CLEAR'

const Invoice = {
  state: {
    invoice: {},
    error:   null,
    loading: false,
    loaded:  false,
    key:     null,
  },

  mutations: {
    [SET_KEY] (state, { key }) {
      state.key = key
      state.loaded = false
      state.invoice = {}
    },
    [LOADING] (state) {
      state.loading = true
      state.loaded = false
      state.error = null
    },
    [LOADED] (state, { invoice }) {
      state.loading = false
      state.loaded = true
      state.invoice = invoice
    },
    [ERR] (state, { err }) {
      state.loading = false
      state.loaded = false
      state.error = err
      state.invoice = {}
    },
    [CLEAR] (state) {
      state.invoice = {}
      state.error = null
      state.loading = false
      state.loaded = false
      state.key = null
    },
  },
  actions: {
    setInvoice ({ state, dispatch, commit }, key) {
      if (state.key !== key) {
        commit(SET_KEY, { key })
      }
      if (!state.loaded) {
        commit(LOADING)
        return dispatch('loadInvoice', key)
          .then(invoice => {
            commit(LOADED, { invoice })
          })
          .catch(e => {
            commit(ERR, { err: e.message })
          })
      } else {
        return Promise.resolve()
      }
    },
    loadInvoice: function ({ commit }, key) {
      commit('noop', {
        meta: {
          analytics: [
            ['event', 'invoice', 'load', key],
          ],
        },
      })
      return firebase.database().ref(`/invoices/${key}`)
        .once('value')
        .then(snapshot => {
          const invoice = snapshot.val()
          invoice.key = key
          invoice.date = new Date(invoice.date * 1000)
          if (invoice.lines) {
            invoice.lines = Object.entries(invoice.lines).map(entry => {
              let line = {
                id: entry[0],
                ...entry[1],
              }
              line.period_start = new Date(line.period_start * 1000)
              line.period_end = new Date(line.period_end * 1000)
              return line
            })
          } else { invoice.lines = [] }

          invoice.appliedBalance = Math.max(invoice.ending_balance - invoice.starting_balance, 0)
          invoice.totalPaid = Math.max(invoice.total - invoice.ending_balance + invoice.starting_balance, 0)

          return invoice
        })
        .catch(e => {
          commit('noop', {
            meta: {
              analytics: [
                ['event', 'invoice', 'error', e.code],
              ],
            },
          })
          throw e
        })
    },
    clearInvoice ({ dispatch, commit }) {
      commit(CLEAR)
    },
  },
}

export default Invoice
