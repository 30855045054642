// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

const Document = {
  state: {
    key:      '',
    dbPath:   '',
    error:    null,
    loading:  false,
    loaded:   false,
    synced:   false,
    mounted:  false,
    document: {},
  },
  mutations,
  actions,
  getters,
}

export default Document
