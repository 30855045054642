<!-- Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied. -->
<template>
  <!--
  Stays open if any of the following are true:
    * User requested
    * subscription change in progress
    * payment change in progress
  -->
  <v-dialog
    :value="open"
    @input="input($event)"
    lazy
    max-width="650px"
    :fullscreen="$vuetify.breakpoint.xsAndDown">
    <subscribe
      in-dialog
      :need="dialogType"
      @input="input($event)"/>
  </v-dialog>
</template>
<script>
import VDialog from 'vuetify/es5/components/VDialog'
import { mapActions, mapState } from 'vuex'

const Subscribe = () => import(/* webpackChunkName: "subscribe" */'@/views/Subscribe.vue')

export default {
  computed: {
    ...mapState({
      isLoggedIn: state => state.user.authenticated,
      dialogOpen: state => state.subDialog.open,
      dialogType: state => state.subDialog.type,
    }),
    open: function () {
      return this.isLoggedIn && this.dialogOpen
    },
  },
  methods: {
    ...mapActions(['closeSubDialog']),
    input: function (evt) {
      if (!evt) {
        this.closeSubDialog()
      }
    },
  },
  watch: {
    open (to) {
      // reset the subscribe form on any change
      if (to) {
        this.$ga.page('/virtual/subscribe-dialog')
      } else {
        this.$ga.page(this.$router)
      }
    },
  },
  components: {
    Subscribe,
    VDialog,
  },
}
</script>
