// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.

export const commits = {
  login:       'USER_LOGIN',
  success:     'USER_LOGIN_SUCCESS',
  reset:       'USER_RESET',
  loaded:      'USER_LOADED',
  loading:     'USER_LOADING',
  set_user:    'USER_SET_USER',
  set_user_ro: 'USER_SET_USERRO',
}

export const mutations = {
  [commits.reset] (state) {
    state.authenticated = false
    state.id = ''
    state.userPath = ''
    state.userRoPath = ''
    state.user = {}
    state.userRo = {}
    state.loading = {
      user:   false,
      userRo: false,
    }
  },
  [commits.loading] (state) {
    state.loading = {
      user:   true,
      userRo: true,
    }
  },
  [commits.success] (state, { user }) {
    state.authenticated = true
    state.id = user.uid
    state.userPath = `/users/${user.uid}`
    state.userRoPath = `/users-ro/${user.uid}`
  },
  [commits.loaded] (state, type) {
    state.loading[type] = false
  },
  [commits.loading] (state) {
    state.loading = {
      user:   true,
      userRo: true,
    }
  },
  [commits.set_user] (state, user) {
    state.user = user
    state.loading.user = false
  },
  [commits.set_user_ro] (state, userRo) {
    state.userRo = userRo
    state.loading.userRo = false
  },
}
