// Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied.
import firebase from 'firebase/app'
import 'firebase/auth'

export const getters = {
  billError: state => {
    if (state.user.hasOwnProperty('status') &&
      state.user.status.hasOwnProperty('error')) {
      return state.user.status.message
    } else {
      return false
    }
  },
  isWorking: state => {
    return state.user.hasOwnProperty('status') && state.user.status.working
  },
  paidStatus: state => {
    if (state.userRo.sub == null) {
      return false
    } else if (state.userRo.sub.hasOwnProperty('plan_id')) {
      return state.userRo.sub.plan_id !== 'free'
    } else {
      // Assume we aren't paid otherwise
      return false
    }
  },
  nextPlan: state => {
    if (state.userRo.sub == null) return 'free'
    if (state.userRo.hasOwnProperty('next')) { return state.userRo.next.plan_id }
    if (state.userRo.sub.hasOwnProperty('plan_id')) { return state.userRo.sub.plan_id }
    return 'free'
  },
  currentPlan: state => {
    if (state.userRo.sub == null) {
      return 'free'
    }
    // I think since we set immediately, should always have a current plan
    return state.userRo.sub.plan_id
  },
  userInvoices: state => {
    if (!state.userRo.hasOwnProperty('invoices')) { return [] }
    return Object.entries(state.userRo.invoices).map(entry => {
      return {
        id: entry[0],
        ...entry[1],
      }
    }).sort((a, b) => {
      if (b.date > a.date) return 1
      if (b.date < a.date) return -1
      return 0
    })
  },
  userCard: state => {
    if (state.userRo.card == null) { return {} }
    return state.userRo.card
  },
  userSub: state => {
    if (state.userRo.sub == null) { return { plan_id: 'free' } }
    return state.userRo.sub
  },
  hasNextPlan: state => {
    return state.userRo.hasOwnProperty('next')
  },
  nextBillDate: state => {
    if (state.userRo.sub == null) return null
    if (state.userRo.sub.plan_id === 'free') return null
    if (state.userRo.sub.cancel_at_period_end && !state.userRo.hasOwnProperty('next')) return null
    return new Date(state.userRo.sub.current_period_end * 1000)
  },
  planEndDate: state => {
    if (state.userRo.sub == null) return null
    if (state.userRo.sub.plan_id === 'free') return null
    if (state.userRo.sub.cancel_at_period_end && !state.userRo.hasOwnProperty('next')) return new Date(state.userRo.sub.current_period_end * 1000)
    return null
  },
  nextSub: state => {
    if (state.userRo.hasOwnProperty('next')) { return state.userRo.next }
    return null
  },
  ccAdding: state => {
    if (state.user.status == null) { return false }
    return !!state.user.status.working
  },
  hasCC: state => {
    if (state.userRo.card == null) { return false }
    return !!state.userRo.card.id
  },
  userDocuments: state => {
    if (state.user.docs == null) { return [] }
    return Object.entries(state.user.docs).map(entry => {
      return {
        key: entry[0],
        ...entry[1],
      }
    })
  },
  userSeenState: state => {
    let seenState = {
      list:   true,
      plan:   true,
      budget: true,
    }
    if (state.user.hasOwnProperty('seen')) Object.assign(seenState, state.user.seen)
    return seenState
  },
  isNewUser: state => {
    if (state.user.hasOwnProperty('seen')) { return !state.user.seen.list }
    return false
  },
  isNewToBudget: state => {
    if (state.user.hasOwnProperty('seen')) { return !state.user.seen.budget }
    return false
  },
  isNewToPlan: state => {
    if (state.user.hasOwnProperty('seen')) { return !state.user.seen.plan }
    return false
  },
  isNewToSim: state => {
    // Should we show the overall new steps?
    // return state.authenticated && !(state.user.seen.budget || state.user.seen.plan)
    if (state.user.hasOwnProperty('seen')) {
      return !(state.user.seen.budget && state.user.seen.plan)
    }
    return false
  },
  accountType: state => {
    switch (state.user.providerId) {
      case firebase.auth.EmailAuthProvider.PROVIDER_ID:
        return 'password'
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
        return 'google'
      case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
        return 'twitter'
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
        return 'facebook'
      default:
        return ''
    }
  },
}
