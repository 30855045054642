<!-- Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied. -->
<template>
  <main>
    <section>
      <v-responsive
        :style="mainGradient">
        <v-layout
          column
          align-center
          justify-center
          class="white--text ma-5"
        >
          <img
            src="/static/logo-white.png"
            width="150px">
          <h1 class="white--text mb-2 display-3">Dart Cannon</h1>
          <!--
          <div class="headline mb-3 text-xs-center">Stop Guessing, Start Estimating</div>
          -->
          <div class="headline mb-3 text-xs-center">Merge estimates into probability ranges</div>
          <v-btn
            class="mt-2"
            dark
            large
            to="/login"
          >
            Get Started For Free
          </v-btn>
        </v-layout>
      </v-responsive>
    </section>
    <section>
      <v-layout
        column
        wrap
        class="mt-5 mb-3"
        align-center
      >
        <v-flex
          xs12
          sm4
          class="mt-3">
          <div class="text-xs-center">
            <h2 class="headline">Advanced Management Statistics, Made Simple</h2>
            <span class="subheading">
              Apply a sophisticated Monte Carlo simulation and turn ranged estimates into percentages.
            </span>
          </div>
        </v-flex>
      </v-layout>
      <v-container grid-list-xl>
        <v-layout
          row
          wrap
          align-center
        >
          <v-flex
            xs12
            md4>
            <v-card>
              <v-card-title
                primary-title
                class="layout justify-center">
                <div class="headline text-xs-center">1. Enter Items</div>
              </v-card-title>
              <v-img
                :src="require('@/assets/items.png')"
                height="200px"/>
            </v-card>
          </v-flex>
          <v-flex
            xs12
            md4>
            <v-card>
              <v-card-title
                primary-title
                class="layout justify-center">
                <div class="headline text-xs-center">2. Add Risks</div>
              </v-card-title>
              <v-img
                :src="require('@/assets/risks.png')"
                height="200px"/>
            </v-card>
          </v-flex>
          <v-flex
            xs12
            md4>
            <v-card>
              <v-card-title
                primary-title
                class="layout justify-center">
                <div class="headline text-xs-center">3. Run Simulation</div>
              </v-card-title>
              <v-img
                :src="resultsImage"
                height="200px"/>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <section>
      <v-img
        :src="resultsImage"
        class="mt-4"
        gradient="to top, rgba(0,0,0,.666), rgba(0,0,0,0.2)"
        height="280px">
        <v-container fill-height>
          <v-layout
            column
            align-center
            justify-center>
            <div class="display-1 white--text mb-3">Have an honest, data driven
            planning conversation</div>
            <em class="white--text title">Talk about what matters, not anecdotes</em>
          </v-layout>
        </v-container>
      </v-img>
    </section>

    <v-layout>
      <v-flex xs12>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap
            align-center>
            <v-flex
              xs12
              md4>
              <v-card class="elevation-0 transparent">
                <v-card-text class="text-xs-center">
                  <v-icon
                    x-large
                    color="primary">monetization_on</v-icon>
                </v-card-text>
                <v-card-title
                  primary-title
                  class="layout justify-center">
                  <div class="headline text-xs-center">Budgeting</div>
                </v-card-title>
                <v-card-text>
                  Go from political wrangling and padded negotiations to
                  realistic conversations about forecasts and likely
                  scenarios.
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs12
              md4>
              <v-card class="elevation-0 transparent">
                <v-card-text class="text-xs-center">
                  <v-icon
                    x-large
                    color="primary">report_problem</v-icon>
                </v-card-text>
                <v-card-title
                  primary-title
                  class="layout justify-center">
                  <div class="headline">Risk Management</div>
                </v-card-title>
                <v-card-text>
                  Fully integrate risks and opportunities into your
                  planning process rather than a side activity.
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs12
              md4>
              <v-card class="elevation-0 transparent">
                <v-card-text class="text-xs-center">
                  <v-icon
                    x-large
                    color="primary">
                    timeline
                  </v-icon>
                </v-card-text>
                <v-card-title
                  primary-title
                  class="layout justify-center">
                  <div class="headline text-xs-center">Project Planning</div>
                </v-card-title>
                <v-card-text>
                  No project is ever going to finish at exactly 5pm on
                  Friday. Understand the range of possibilities and plan
                  based on actual ranges of outcomes.
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <section> <!-- Pricing -->
      <v-container grid-list-xl>
        <v-layout
          row
          wrap
          justify-center
          class="mb-5">
          <v-flex
            xs12
            class="mt-2">
            <div class="text-xs-center">
              <h2 class="display-1">Pricing and Plans</h2>
            </div>
          </v-flex>
          <v-flex
            xs12
            sm4>
            <v-card>
              <v-card-text class="text-xs-center pb-2">
                <v-icon
                  x-large
                  color="primary">free_breakfast</v-icon>
              </v-card-text>
              <v-card-title
                class="layout justify-center py-2">
                <div class="headline text-xs-center">Free</div>
              </v-card-title>
              <v-card-text style="height: 185px">
                <p>
                  Get started for free, no credit card required, and simulate
                  basic projects and budgets.
                </p>
                <p>
                  Upgrade to a professional plan at any time when you need
                  more advanced features.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  to="/login"
                  dark>Get Started</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex
            xs12
            sm4>
            <v-card>
              <v-card-text class="text-xs-center pb-2">
                <v-icon
                  x-large
                  color="primary">work</v-icon>
              </v-card-text>
              <v-card-title
                class="layout justify-center py-2">
                <div class="headline text-xs-center">Professional</div>
              </v-card-title>
              <v-card-text style="height: 185px">
                All paid plans unleash the full power of DartCannon, inluding:
                <ul class="my-1 ml-3">
                  <li>Unlimited Saved Simulations</li>
                  <li>High Resolution Simulations</li>
                  <li>Excel Import/Export</li>
                  <li>Correlated Outcomes</li>
                </ul>
                The Professional plan is $10/month or $100/year.
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  to="/subscribe"
                  dark>Subscribe</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex
            xs12
            sm4>
            <v-card>
              <v-card-text class="text-xs-center pb-2">
                <v-icon
                  x-large
                  color="primary">business</v-icon>
              </v-card-text>
              <v-card-title
                class="layout justify-center py-2">
                <div class="headline text-xs-center">Enterprise</div>
              </v-card-title>
              <v-card-text style="height: 185px">
                We provide custom pricing packages for enterprise customers who
                want to provide access to DartCannon for all employees. Contact
                us today to discuss pricing options.
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  href="mailto:sales@dartcannon.com"
                  dark>Contact Us</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <section>
      <v-container grid-list-xl>
        <v-layout
          row
          wrap
          justify-center >
          <v-flex
            xs12
            sm4>
            <v-card class="elevation-0 transparent">
              <v-card-title
                primary-title
                class="layout justify-center">
                <div class="headline">Company info</div>
              </v-card-title>
              <v-card-text>
                DartCannon was founded on the idea that advanced tools should
                be available to all managers in an easy to use form. After
                gestating for 10 years, the time and technology is finally at a
                point where tools which previously cost $1,000's per seat can
                be accessible by everyone.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex
            xs12
            sm4
            offset-sm1>
            <v-card class="elevation-0 transparent">
              <v-card-title
                primary-title
                class="layout justify-center">
                <div class="headline">Contact us</div>
              </v-card-title>
              <v-card-text>
                Have a question, comment or suggestion? We'd love to hear from
                you...
              </v-card-text>
              <v-list class="transparent">
                <v-list-tile>
                  <v-list-tile-action>
                    <v-icon class="blue--text text--lighten-2">place</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Washington DC</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-action>
                    <v-icon class="blue--text text--lighten-2">email</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <a
                        href="mailto:support@dartcannon.com">
                        support@dartcannon.com
                      </a>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

  </main>
</template>
<script>
import VImg from 'vuetify/es5/components/VImg'
import VResponsive from 'vuetify/es5/components/VResponsive'
import * as VGrid from 'vuetify/es5/components/VGrid'
import VIcon from 'vuetify/es5/components/VIcon'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VList from 'vuetify/es5/components/VList'
import VBtn from 'vuetify/es5/components/VBtn'

import resultsImage from '../docs/help/screenshots/holiday-results-2.png'

export default {
  data () {
    return { resultsImage }
  },
  computed: {
    mainGradient () {
      return `background-image: linear-gradient(45deg, ${this.$vuetify.theme.primary}, #111);`
    },
  },
  components: {
    ...VGrid,
    ...VCard,
    ...VList,
    VIcon,
    VBtn,
    VImg,
    VResponsive,
  },
}
</script>
